@import "../../shared/font";

.birth-day-selector {
  @include font-basic;

  display: flex;
  flex-direction: column;

  label {
    @include font-attribute(17px, #413C33, $weight: 500);
    margin-bottom: 14px;
  }

  select {
    width: 120px;
    height: 38px;
    padding: 7px 14px;
    background-color: rgba(255, 255, 255, 0.26);
    border-radius: 5px;
    border: 1px white;

    caret-color: rgba(65, 60, 51, 0.80);

    @include font-attribute(17px, rgba(65, 60, 51, 0.80), $weight: 500);
  }

  .controls {
    display: flex;
    flex-direction: row;
    gap: 14px;

  }

  .year-selector {
    width: 120px;
  }

  .month-selector {
    width: 90px;
  }

  .day-selector {
    width: 90px;
  }

  option {
    @include font-attribute(30px, rgba(65, 60, 51, 0.80), $weight: 500);
  }
}
