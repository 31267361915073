@import '../../shared/font';

.skin-turn-over-text-block {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .skin-turn-over-text-content {
    background-color: rgba(247, 245, 241, 0.52);
    padding: 30px;
    border-radius: 10px;

    //word-break: keep-all;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .skin-turn-over-text-content-left {
    //margin-right: 14px
    flex: 50%;
  }

  .skin-turn-over-text-content-right {
    //margin-left: 14px
    flex: 50%;
  }

  .skin-turn-over-text-content-column {
    width: 33%;
    flex-grow: 1;
  }

  .text-block-title {
    @include font-basic;
    @include font-attribute(16px, #413C33, $weight: 500);

    text-align: left;

  }

  .text-block-content {
    //text-align: center;
  }
}

