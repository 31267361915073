@import "../../shared/font";
@import "../../shared/container";

.MuiDialog-paper {
  background-color: #F6F3EE;
  overflow-x: hidden;
}

.MuiDialogContent-root {
  overflow-x: hidden;
  max-height: 864px;
}

.survey-agreement-dialog-icon {
  margin-top: 65px;
  margin-bottom: 25px;
  @include text-align-center;
}

.survey-agreement-dialog-title {
  @include font_basic;
  @include font_attribute(30px, #413C33, $weight: 500, $letter-spacing: -1.086px);
  @include text-align-center;
}

.survey-agreement-dialog-text {
  padding: 20px;
  margin: 58px 48px 100px;
  border-radius: 10px;
  max-height: 314px;

  overflow: scroll;

  @include font-pretendard;

  p {
    @include font-attribute(14px, #413C33, 300);
    @include no-margin;
  }

  strong {
    @include font-attribute(14px, #413C33, 500);
    @include no-margin;
  }
}
