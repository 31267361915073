@import "../../../shared/font";

.result-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.result-form-title {
  margin-top: 90px;
  @include text-align-center;
  @include font-basic;
  @include font-attribute(19px, rgba(195, 185, 171, 0.50), $weight: 500);
}

.result-form-progress {
  margin-top: 45px;
  margin-bottom: 138px;
  text-align: center;
  padding: 0 120px;
  span {
    //width: 500px;
    //display: table;
    //margin: 0 auto;
  }
}

.result-form-description {
  @include text-align-center;
  @include font-pretendard;
  @include font-attribute(22px, #413C33, $weight: 500);
}

.result-form-content {
  margin-top: 89px;
  padding: 0 60px;

  @include text-align-center;
  @include font-basic;
  @include font-attribute(18px, #807C74, $weight: 400, $line-height: 50%);
}

.result-form-content-notice {
  margin-top: 150px;
  @include text-align-center;
  @include font-basic;
  @include font-attribute(17px, #807C74, $weight: 400, $line-height: 50%);
}

.result-form-action-bar {
  display: flex;
  flex-direction: row;

  margin-top: auto;
  margin-bottom: 81px;

  .MuiButtonBase-root {
    //height: 26px;
    //border: none;
    background-color: #ECEAE5;

    @include font-pretendard;
    @include font-attribute(22px, #CEC5B8, $weight: 600);
  }

  .MuiButtonBase-root:disabled {
    background-color: #D8D2CA;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .space {
    flex-grow: 1;
  }
}

