@import '../../shared/font';

.sub-text-block {
  .content {
    @include font-pretendard;
    @include font_attribute(13px, rgba(65, 60, 51, 0.90), $line-height: 150%, $weight: 200);
    text-align: left;

    p {
      padding: 0;
      margin: 0;
    }

    strong {
      font-weight: 400;
    }
  }
}

