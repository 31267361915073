@mixin font-attribute($size, $color, $weight: 300, $line-height: 138%, $letter-spacing: -0.40px) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin font-basic {
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

@mixin font-pretendard {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

@mixin text-align-center {
  text-align: center;
}
