@import "../../shared/font";
@import "../../shared/container";

.menu {
  padding-top: 24px;
  padding-bottom: 34px;

  @include default-container-paddings;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    text-decoration: none;
    display: block;
    margin-right: 32px;
    @include font-basic;
    @include font-attribute(18px, #413C33, $weight: 300)
  }

  .activated-link {
    text-decoration: underline;
    font-weight: 400;
  }
}
