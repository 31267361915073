@import "../../shared/font";

.text-input-field {
  @include font-basic;

  display: flex;
  flex-direction: column;

  label {
    @include font-attribute(17px, #413C33, $weight: 500);
    margin-bottom: 14px;
  }

  input {
    height: 38px;
    padding: 7px 14px;
    background-color: rgba(255, 255, 255, 0.26);
    border-radius: 5px;
    border: 1px white;

    caret-color: rgba(65, 60, 51, 0.80);

    @include font-attribute(17px, rgba(65, 60, 51, 0.80), $weight: 500);
  }

  .text-input-field-hint {
    padding: 0;

    @include font-pretendard;
    @include font-attribute(13px, rgba(65, 60, 51, 0.80));
  }

}


