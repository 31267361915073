@import "../../shared/font";

.survey-result-layout {
  //display: flex;
  //flex-direction: column;

  width: auto;
  height: 100%;
  padding: 40px;
  background-color: #ECEAE5;
  overflow-x: hidden;
  overflow-y: auto;
}

.survey-result-layout-top-notice {
  @include font-basic;
  @include font-attribute(15px, rgba(65, 60, 51, 0.40), $weight: 500);

  margin-top: 32px;
  margin-bottom: 40px;
}

@media all and (max-width: 768px) {
   .survey-result-layout-title {
    @include font-basic;
    @include font-attribute(22px, '#413C33', $weight: 400);
  }

  .survey-result-layout-solutions-content {
    gap: 10px;
    height: 320px;
  }

  .survey-result-layout-tags-item {
    @include font-attribute(11px, rgba(65, 60, 51, 0.58), $weight: 500);
  }
}

@media all and (min-width: 769px) {
   .survey-result-layout-title {
    @include font-basic;
    @include font-attribute(38px, '#413C33', $weight: 400);
  }

  .survey-result-layout-solutions-content {
    gap: 25px;
    min-height: 300px;
  }

  .survey-result-layout-tags-item {
    @include font-attribute(14px, rgba(65, 60, 51, 0.58), $weight: 500);
  }
}

.survey-result-layout-sub-title {
  @include font-pretendard;
  @include font-attribute(16px, rgba(65, 60, 51, 0.80), $weight: 500);

  margin-top: 20px;
  margin-bottom: 30px;
}

.survey-result-layout-description {
  @include font-pretendard;
  @include font-attribute(14px, rgba(65, 60, 51, 0.70), $weight: 400);

  p {
    padding: 0;
    margin: 0;
  }
}

.survey-result-layout-tags {
  margin-top: 40px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.survey-result-layout-tags-item {
  @include font-pretendard;

  padding: 15px;
  border-radius: 4.101px;
  background: rgba(255, 255, 255, 0.22);
}

.survey-result-layout-solutions-title {
  margin-bottom: 36px;

  @include font-pretendard;
  @include font-attribute(18px, rgba(65, 60, 51, 0.58), $weight: 500);
}

.survey-result-layout-solutions-content {
  display: flex;
  flex-direction: row;
  //gap: 25px;
  overflow-y: hidden;
  overflow-x: auto;
}


.survey-result-layout-solutions-content-item {

}
