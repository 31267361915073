@import "../../shared/font";
@import "../../shared/container";

.product-layout {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .title {
    @include font-basic;

    color: #413C33;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 24px;

    @include default-container-paddings;

    p {
      margin: 0;
      padding: 0;
      line-height: 130%;
    }
  }

  .sub-title {
    @include font-basic;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 24px;
  }

  .product-layout-content {
    @include default-container-paddings;
    display: flex;
    flex-direction: column;

    flex-grow: 1;

    p {
      padding: 0;
      margin: 0;
      @include font-attribute(14px, rgba(65, 60, 51, 0.90), $weight: 300, $line-height: 153%, $letter-spacing:-0.423px);
    }

    .description1 {
    }

    .description2 {
      margin-top: auto;
      margin-bottom: 13px;
    }

    .description3 {
      margin-top: auto;
      margin-bottom: 40px;
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .notice {
    @include font-pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 132%;
    letter-spacing: -0.29px;
    //margin-top: 47px;
    //margin-bottom: 31px;
    color: rgba(65, 60, 51, 0.80);

    margin-top: auto;
    margin-bottom: 92px;
  }
}


.MuiFab-primary {
  background-color: #F6F3EE;
}
