@import "../../shared/font";
@import "../../shared/container";

.privacy-agreement-dialog {

}

.MuiDialog-paper {
  background-color: #F6F3EE;
  overflow-x: hidden;
}

.MuiDialogContent-root {
  overflow-x: hidden;
  max-height: 864px;
}

.MuiDialogTitle-root {
  @include font-basic;
  @include font-attribute(30px, #413C33, 500);
  @include text-align-center;
  margin-top: 30px;
}

.notice-text {
  margin-top: 10px;
  margin-bottom: 32px;

  p {
    @include font-pretendard;
    @include font-attribute(16px, #413C33, 400, $line-height: 150%);
    @include text-align-center;
    @include no-margin;
  }
}

.privacy-policy-text {
  padding: 20px;
  margin: 35px;
  border-radius: 10px;
  background-color: #FFFCF8;
  max-height: 314px;

  overflow: scroll;
  //white-space: nowrap;

  @include font-pretendard;

  p {
    @include font-attribute(12px, #413C33, 300);
    @include no-margin;
  }

  strong {
    @include font-attribute(12px, #413C33, 500);
    @include no-margin;
  }
}

.agreement-text {
  @include text-align-center;
  margin-bottom: 50px;

  span {
    @include font-pretendard;
    @include font-attribute(16px, #413C33, 500);
  }
}
