@import "../../shared/font";

.consulting-action-button {
  .MuiFab-circular {
    background-color: #F6F3EE;
  }

  .consulting-action-button-image {
    width: 29px;
  }
}

.consulting-action-button-popover {

}

.consulting-action-button-popover-text {
  width: 226px;
  height: 95px;

  display: flex;
  flex-direction: column;

  justify-content: center;

  button {
    padding-left: 50px;

    @include font-basic;
    @include font-attribute(18px, rgba(74, 60, 33, 0.70), $weight: 500);
  }
}

.skin-report-button {
  padding-bottom: 10px;
}
