@import "../../../shared/font";

.survey-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.survey-form-title {
  margin-top: 90px;
  @include text-align-center;
  @include font-basic;
  @include font-attribute(19px, rgba(195, 185, 171, 0.50), $weight: 500);
}

.survey-form-progress {
  margin-top: 45px;
  margin-bottom: 138px;
  text-align: center;
  padding: 0 120px;
  span {
    //width: 500px;
    //display: table;
    //margin: 0 auto;
  }
}

.survey-form-description {
  height: 60px;
  @include text-align-center;
  @include font-pretendard;
  @include font-attribute(22px, #413C33, $weight: 500);
}

.survey-form-items {
  margin-top: 190px;
  padding: 0 40px;
  height: 115px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 76px;
  grid-column-gap: 40px;
}


.survey-form-items-item {
  @include font-pretendard;
  @include font-attribute(18px, rgba(65, 60, 51, 0.60), $weight: 500);
  word-wrap: break-word;
}

.survey-form-action-bar {
  display: flex;
  flex-direction: row;

  margin-top: auto;
  margin-bottom: 81px;

  .MuiButtonBase-root {
    //height: 26px;
    //border: none;
    background-color: #ECEAE5;

    @include font-pretendard;
    @include font-attribute(22px, #CEC5B8, $weight: 600);
  }

  .MuiButtonBase-root:disabled {
    color: #D8D2CA;
  }

  .align-vcenter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .space {
    flex-grow: 1;
  }
}

