.password-page {
  width: 100%;
  height: 100%;
}

.password-page-layout {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.password-page-password-field {
  width: 100%;
}

.password-page-button-field {
  width: 100%;
  text-align: right;
  justify-content: right;
}
