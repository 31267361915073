@import "../../shared/font";
@import "../../shared/container";

.skin-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ECEAE5;
  overflow: auto;

  .skin-layout-title {
    @include font-basic;
    color: #413C33;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 24px;
  }

  .skin-layout-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include default-container-paddings;
    padding-bottom: 40px;
  }

  .skin-layout-image {
    margin-top: 50px;
    margin-bottom: 35px;
    width: 620px;
  }

  .notice {
    @include font-pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 132%;
    letter-spacing: -0.29px;
    //margin-top: 47px;
    //margin-bottom: 31px;
    color: rgba(65, 60, 51, 0.80);

    margin-top: auto;
    margin-bottom: 48px;
  }
}
