@import '../../shared/font';

.text-block {
  .text-block-title p {
    @include font-basic;
    @include font_attribute(16px, #484848, $weight: 500);
    margin-bottom: 24px;
    text-align: left;

    p {
      margin: 0;
    }
  }

  .text-block-content {
    @include font-pretendard;
    @include font_attribute(14px, rgba(65, 60, 51, 0.90), $line-height: 150%);
    text-align: left;

    p {
      margin: 0;
    }
  }
}

