.swiper {
  width: 810px;
  height: 1080px;
  margin: 0 auto;
}

.swiper-slide {
  background: #f1f1f1;
  color: #000;
  text-align: center;
  //line-height: 300px;
}

.swiper-button-prev {
  color: rgba(215, 209, 200, 0.5)
}

.swiper-button-next {
  color: rgba(215, 209, 200, 0.5)
}
