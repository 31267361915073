@import "../../../shared/font";

.privacy-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.privacy-form-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.privacy-form-content-title {
  @include font-basic;
  @include font-attribute(30px, #413C33, 500);
  margin: 23px 0;
}


.privacy-form-content-description {
  margin-bottom: 50px;

  p {
    margin: 0;
    padding: 0;
  }
}

.privacy-form-content-description-text {
  @include font-pretendard;
  @include font-attribute(17px, #413C33, $weight: 500);
  margin-bottom: 23px;
}

.privacy-form-content-description-additional {
  @include font-pretendard;
  @include font-attribute(13px, #413C33);
}

.privacy-form-content-description-form {
  display: flex;
  flex-direction: column;
  gap: 88px;
}



.privacy-form-action-bar {
  text-align: right;
  margin-top: auto;
  margin-bottom: 81px;

  .MuiButtonBase-root {
    background-color: #ECEAE5;

    @include font-pretendard;
    @include font-attribute(22px, #CEC5B8, $weight: 600);
  }

  .MuiButtonBase-root:disabled {
    color: #D8D2CA;
  }
}
